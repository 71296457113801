@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Montserrat", serif;
  background-color: #f0f2f5;
  color: black;
  margin: 0;
  padding: 0;
}

pre {
  font-family: "Montserrat", serif;
  font-size: 12px;
  color: #4b57b1;
  margin-top: -0.2em;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 7%;
  padding-top: 30px;
}

.App-header {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60em;
  height: 54vh;
  position: relative;
}

h2 {
  color: #121c69;
  font-size: 1.6em;
  margin: 10px;
  text-align: center;
}

.buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.salary-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 6px;
}

@media (min-width: 768px) {
  .salary-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

label {
  width: 40%;
  font-weight: bold;
}

.percentage-input,
.experience-input {
  width: 60%;
  padding-right: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}

.percentage-symbol {
  margin-left: -20px;
  font-weight: bold;
}

.custom-select {
  border: none;
  border-bottom: 1px dotted #000;
  outline: none;
  width: fit-content;
  font-family: "Montserrat", serif;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  color: #4b57b1;
  padding-right: 10px;
}

option {
  font-family: "Montserrat", serif;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  color: #4b57b1;
}

.results-div {
  font-weight: 600;
  font-size: 14px;
  color: #4b57b1;
}
.form-container {
  flex: 1;
  padding-right: 1px;
  border-right: 1px solid #121c69;
  height: 308px;
}

.results-container {
  flex: 1;
  padding-left: 5px;
}

label {
  font-weight: bold;
  margin-bottom: 2px;
  display: inline-block;
}

input[type="number"] {
  width: 12%;
  padding: 4px;
  margin: 5px 0 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #4b57b1;
  color: #fff;
  border: none;
  padding: 6px 1px;
  text-align: center;
  font-size: small;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  width: 80px;
  transition: background-color 0.3s;
  margin-right: 0.5em;
  margin-bottom: 0.7em;
  margin-top: 1em;
}

button:hover {
  background-color: #121c69;
}

div {
  margin-bottom: 0.2em;
}

.detailed {
  font-weight: 600;
  margin-bottom: 0.3em;
}
